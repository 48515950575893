// extracted by mini-css-extract-plugin
export var appSlider = "blog_card-module--app-slider--2EZqf";
export var container = "blog_card-module--container--1j2tn";
export var content = "blog_card-module--content--1nNoV";
export var date = "blog_card-module--date--3x4fE";
export var defaultContainer = "blog_card-module--default-container--3_7sY";
export var description = "blog_card-module--description--wxVBF";
export var featureSlider = "blog_card-module--feature-slider--2QXAP";
export var imageContainer = "blog_card-module--imageContainer--9eT3w";
export var innerPagesSlider = "blog_card-module--inner-pages-slider--2p58C";
export var leftAppSlide = "blog_card-module--left-app-slide--3k5f6";
export var link = "blog_card-module--link--1enHu";
export var logoSlider = "blog_card-module--logo-slider--1vFfH";
export var phoneAppSelect = "blog_card-module--phone-app-select--3y5GS";
export var phoneCaseSelect = "blog_card-module--phone-case-select--2qWAv";
export var rightAppSlide = "blog_card-module--right-app-slide--144ol";
export var slickActive = "blog_card-module--slick-active--3Atzj";
export var slickArrow = "blog_card-module--slick-arrow--QTNeD";
export var slickCenter = "blog_card-module--slick-center--3Db9l";
export var slickDots = "blog_card-module--slick-dots--gDnWu";
export var slickNext = "blog_card-module--slick-next--2IRP9";
export var slickPrev = "blog_card-module--slick-prev--32fAJ";
export var slickSlide = "blog_card-module--slick-slide--2AHpB";
export var slickSlider = "blog_card-module--slick-slider--2JmkI";
export var slickTrack = "blog_card-module--slick-track--pOvk7";
export var solvingSlider = "blog_card-module--solving-slider--d8Bmr";
export var title = "blog_card-module--title--1qY-4";