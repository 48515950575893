// extracted by mini-css-extract-plugin
export var appSlider = "blog_archive-module--app-slider--MsknJ";
export var blog = "blog_archive-module--blog--2-16i";
export var blogArchive = "blog_archive-module--blog-archive--3OksK";
export var blogArchiveContainer = "blog_archive-module--blog-archive-container--3ilDC";
export var blogArchiveSlider = "blog_archive-module--blog-archive-slider--3eWBE";
export var blogArchiveSliderItem = "blog_archive-module--blog-archive-slider-item--3pMrl";
export var blogContainer = "blog_archive-module--blog-container--175Ih";
export var blogs = "blog_archive-module--blogs--2qCri";
export var contentWrapper = "blog_archive-module--content-wrapper--1oq1q";
export var defaultContainer = "blog_archive-module--default-container--1tEKm";
export var featureSlider = "blog_archive-module--feature-slider--PZSA9";
export var innerPagesSlider = "blog_archive-module--inner-pages-slider--2lod1";
export var leftAppSlide = "blog_archive-module--left-app-slide--3kDaF";
export var logoSlider = "blog_archive-module--logo-slider--1vSxL";
export var phoneAppSelect = "blog_archive-module--phone-app-select--3hBLz";
export var phoneCaseSelect = "blog_archive-module--phone-case-select--2m5Q_";
export var rightAppSlide = "blog_archive-module--right-app-slide--38aPO";
export var slickActive = "blog_archive-module--slick-active--1eKhX";
export var slickArrow = "blog_archive-module--slick-arrow--14aIH";
export var slickCenter = "blog_archive-module--slick-center--1Tzkg";
export var slickDots = "blog_archive-module--slick-dots--27yqw";
export var slickNext = "blog_archive-module--slick-next--1kywg";
export var slickPrev = "blog_archive-module--slick-prev--3reQj";
export var slickSlide = "blog_archive-module--slick-slide--1xoxz";
export var slickSlider = "blog_archive-module--slick-slider--5drAb";
export var slickTrack = "blog_archive-module--slick-track--3-2oM";
export var solvingSlider = "blog_archive-module--solving-slider--2iEaI";
export var subtitle = "blog_archive-module--subtitle--17wiW";
export var title = "blog_archive-module--title--wl6wr";
export var titleWrapper = "blog_archive-module--title-wrapper--1D97d";