import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from "../components/Layout"
import Hero from "../sections/hero/hero"
import BlogArchive from "../sections/blog_archive/blog_archive"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import { getSeo } from "../utils/seo"

import BlogsData from "../utils/blogs-queries"

export default function Blogs() {
  const { hero, blogs } = BlogsData()

  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}>
      {/* <Seo title={"Blogs"} /> */}
      <Hero hero={hero} styles={hero_styles} nextSection="blogs" />
      <BlogArchive blogs={blogs} />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = BlogsData()
  return getSeo(seo)
}
