import { graphql, useStaticQuery } from "gatsby"

export default function BlogsData() {
  return useStaticQuery(graphql`
    query queryBlogs {
      seo: sanitySeo(slug: { current: { eq: "blogs" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }
      hero: sanityHero(slug: { current: { eq: "blogs" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      blogs: allSanityBlog(sort: { fields: createDate, order: DESC }) {
        nodes {
          title
          summary
          image {
            image {
              asset {
                url
              }
            }
            alt
          }
          createDate(formatString: "YYYY/MM/DD")
          slug {
            current
          }
        }
      }
    }
  `)
}
