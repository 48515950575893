import React from "react"
import { Link } from "gatsby"
import * as styles from "./blog_card.module.scss"
import { displayDate } from "../../utils/functions"
import Arrow from "../../../assets/read_more_arrow.svg"

export default function BlogCard(props) {
  const { blogDetails, cardWidth } = props
  return (
    <Link
      to={`/${blogDetails.createDate.replaceAll("-", "/")}/${
        blogDetails?.slug?.current ? blogDetails?.slug.current : "#"
      }`}
      className={styles.container}
      style={{ width: cardWidth ? cardWidth : "" }}
    >
      <div className={styles.imageContainer}>
        <img
          src={blogDetails?.image?.image?.asset.url}
          alt={blogDetails?.image?.alt}
        />
      </div>
      <div className={styles.content}>
        <p className={styles.date}>{displayDate(blogDetails?.createDate)}</p>
        <h6 className={styles.title}>{blogDetails?.title}</h6>
        <p className={styles.description}>{blogDetails?.summary}</p>
        <div className={styles.link}>
          Read More <Arrow />
        </div>
      </div>
    </Link>
  )
}
